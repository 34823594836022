@import "./Shop-MediaQuery.css";

/*@import url(https://fonts.googleapis.com/css?family=Montserrat:900);
@import url(https://fonts.googleapis.com/css?family=Cormorant:300,400,400i,500,500i,600|Lato:300,400,400i,500,500i,600&subset=latin-ext&ver=1.0.0);*/

/*:root {
	--primary-color-light: #3b9ce6;
	--primary-color: #2897ea;
	--primary-color-dark: #015696;
	--primary-color-darker: #014477;
	--secondary-color: #f3f0f1;
	--secondary-color-dark: #e0dfdf;
	--secondary-color-darker: #adacac;
	--secondary-color-darkest: #8a8a8a;
	--block-height: 3.5rem;
	--primary-font: Didot, Helvetica;
	--footer-height: 9rem;
	--header-height: 6rem;
}*/

:root {
	--block-height: 3.5rem;
}

/*html,
body {
	height: 100%;
	overflow: hidden;
	font-family: var(--primary-font) !important;

	
	font-size: 14px;
	-webkit-text-size-adjust: 100%;
	
	font-variant-ligatures: none;
	-webkit-font-variant-ligatures: none;
	
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	
	//	font-smoothing: antialiased;
	//	-webkit-font-smoothing: antialiased;
	
	text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}*/

body > #app {
	height: 100%;
}

body > #app,
body > #app > .NoApp,
body > #app > .AppShop,
body > #app > .AppPayment {
	height: 100%;
	margin: 0 !important;
}

/* ================================================ GLOBAL ================================================ */

/*input[type="text"]:not(.search input) {
	font-family: var(--primary-font) !important;
	font-weight: 400 !important;
	font-style: normal !important;
	font-size: 1rem;
	color: #605c5c !important;
}*/

.ui.disabled.checkbox label,
input:disabled {
	opacity: 1 !important;
}

/*[predicate="Name"] {
	font-size: 1.2857142857142858rem !important;
}*/

/*textarea {
	font-family: var(--primary-font) !important;
	font-weight: 400 !important;
	font-style: normal !important;
	font-size: 1rem;
	color: #605c5c !important;
}*/

/*.dropdown{
    border: none !important;
  }

.ui.button {
	font-family: var(--primary-font);
	font-size: 1rem;
	font-weight: 400;
	font-style: normal;
	color: var(--primary-color-dark);
	background-color: var(--secondary-color);
}

.button:hover {
	color: var(--primary-color-dark);
	background-color: var(--secondary-color-dark);
}

.button.primary {
	font-family: var(--primary-font);
	font-size: 1rem;
	font-weight: 400;
	font-style: normal;
	color: var(--secondary-color);
	background-color: var(--primary-color);
}

.button.primary:hover {
	color: var(--secondary-color);
	background-color: var(--primary-color);
}

.button.secondary {
	font-family: var(--primary-font);
	font-size: 1rem;
	font-weight: 400;
	font-style: normal;
	color: var(--primary-color-dark);
	background-color: var(--secondary-color);
}

.button.secondary:hover {
	color: var(--primary-color-dark);
	background-color: var(--secondary-color);
}*/

.ui.dimmer {
	background-color: rgb(0 0 0 / 70%) !important;
}

.errorView {
	position: absolute !important;
	bottom: 1%;
	right: 1%;
	z-index: 999999;
}

*:focus {
	outline: none;
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}

.ui.label.siteLabel {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate3d(-50%, 0, 0);
	z-index: 9999999;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	animation: blinker 2s linear infinite;
}

/*.truncate-overflow {
	--lh: 1rem;
	--max-lines: 3;
	position: relative;
	max-height: calc(var(--lh) * var(--max-lines));
	overflow: hidden;
	padding-right: 1rem !important;
}

.truncate-overflow::before {
	position: absolute;
	content: "...";
	inset-block-end: 0;
	inset-inline-end: 0; 
}
.truncate-overflow::after {
	content: "";
	position: absolute;
	inset-inline-end: 0;
	width: 1rem;
	height: 1rem;
	background: white;
}*/

/************************* Page Not Found *************************/

.pageNotFound {
	background-color: var(--secondary-color);
	height: 100%;
	display: flex !important;
}

.pageNotFound > .msg {
	margin: auto !important;
	text-align: center;
	background: transparent;
	box-shadow: none !important;
	border: none !important;
}

.pageNotFound > .msg > .msgBox {
	max-width: 37.142857142857146rem;
	width: 100%;
	line-height: 1.4;
	text-align: center;
}

.pageNotFound > .msg > .msgBox > .header {
	position: relative;
	height: 17.142857142857142rem;
}

.pageNotFound > .msg > .msgBox > .header h1 {
	font-family: "Montserrat", sans-serif;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	font-size: 18rem;
	font-weight: 900;
	margin: 0px;
	color: #262626;
	text-transform: uppercase;
	letter-spacing: -2.857142857142857rem;
	margin-left: -1.4285714285714286rem;
}

.pageNotFound > .msg > .msgBox > .header h1 > span {
	text-shadow: -8px 0px 0px #fff;
}

.pageNotFound > .msg > .msgBox > .header h1 > span:nth-child(2) {
	color: var(--secondary-color-darkest);
}

.pageNotFound > .msg > .msgBox > .header h3 {
	position: relative;
	font-size: 1.12rem;
	font-weight: 700;
	text-transform: uppercase;
	color: #262626;
	margin: 0px;
	letter-spacing: 0.2rem;
	padding-left: 0.42rem;
}

.pageNotFound > .msg > .msgBox > h2 {
	font-family: var(--primary-font);
	font-size: 1.3rem;
	font-weight: 400;
	color: #000;
	margin-top: 0;
	margin-bottom: 1.78rem;
}

/************************* Server Under Maintenance *************************/

.serverUnderMaintenance {
	background-color: var(--secondary-color);
	height: 100%;
	display: flex !important;
}

.serverUnderMaintenance > .msg {
	margin: auto !important;
	text-align: center;
	background: transparent;
	box-shadow: none !important;
	border: none !important;
}

.serverUnderMaintenance > .msg > .msgBox {
	max-width: 40rem;
	text-align: center;
}

.serverUnderMaintenance > .msg > .msgBox > img {
	box-shadow: -3px -3px 10px rgba(255, 255, 255, 0.5), 6px 6px 10px rgba(0, 0, 0, 0.15) !important;
	border-color: var(--secondary-color);
}

.serverUnderMaintenance > .msg > .msgBox > .header {
	position: relative;
	margin: 2rem 0 0.5rem;
}

.serverUnderMaintenance > .msg > .msgBox > .header h3 {
	position: relative;
	font-size: 1.2rem;
	font-weight: 700;
	text-transform: uppercase;
	color: #262626;
	margin: 0px;
	letter-spacing: 0.2rem;
}

.serverUnderMaintenance > .msg > .msgBox > h2 {
	font-family: var(--primary-font);
	font-size: 1.1rem;
	font-weight: 400;
	color: #000;
	margin-top: 0;
	margin-bottom: 0.5rem;
}

/* ================================ LOGIN SCREEN ================================ */

.ui.modal.loginPopup > .content {
	padding: 0.5rem !important;
}

/*.ui.grid.shopLoginView {
	margin: 0;
}

.ui.grid.shopLoginView > .title {
	background-color: white;
}

.shopLoginView > .title .header {
	font-weight: 500;
	font-size: 2rem;
	padding: 0 5%;
	color: var(--primary-color-dark);
}

.ui.grid.shopLoginView > .body {
	background-color: var(--secondary-color);
	border-top-left-radius: 1rem;
	border-top-right-radius: 1rem;
	padding: 0;
}

.ui.grid.shopLoginView > .row.body > .column {
	border-top: none !important;
}

.login-form {
	display: flex !important;
	flex-direction: column;
	justify-content: flex-start;
	padding-left: 5% !important;
	padding-right: 5% !important;
}

.login-form > .ui.form > .field {
	position: relative;
	margin-bottom: 2rem;
}

.login-form > .ui.form > .field > .input > .icon {
	color: black;
}

.login-form input {
	color: black;
	background: #ffffff;
	font-family: var(--primary-font) !important;
}

.login-form > .ui.form > .field > .ui.input.error > input {
	border-color: #e0b4b4 !important;
	color: #9f3a38 !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

.login-form > .ui.form > .field .fieldErrorMsg {
	position: absolute;
	right: 2%;
	color: #9f3a38;
}

.login-form > .ui.form .error.error input:-webkit-autofill:focus,
.login-form > .ui.form .error.error input:-webkit-autofill {
	background-color: #fff6f6 !important;
	-webkit-box-shadow: 0 0 0 100px #fffaf0 inset !important;
	box-shadow: 0 0 0 100px #fffaf0 inset !important;
	border-color: #e0b4b4 !important;
} 

.login-form .ui.button {
	position: relative;
	margin-top: 0.5rem !important;
	display: block !important;
	box-shadow: -6px -6px 10px rgba(255, 255, 255, 0.8),
		6px 6px 10px rgba(0, 0, 0, 0.15) !important;
	font-size: 1.5rem !important;
	transition: padding 0.5s !important;
	padding: 1rem !important;
}

.login-form .ui.button.primary {
	box-shadow: -10px -10px 8px rgba(255, 255, 255, 0.9),
		8px 8px 12px rgba(0, 0, 0, 0.25) !important;
}

.login-form .ui.button:hover {
	transition: all 0.5s;
	padding: 1rem 3rem 1rem 1rem !important;
}

.login-form .ui.button::after {
	content: "\f054";
	font-family: Icons;
	font-weight: 400;
	font-size: 1rem;
	position: absolute;
	opacity: 0;
	transition: all 0.5s;
	transform: translate(-50%, -50%);
}

.login-form .ui.button:hover::after {
	opacity: 1;
	transition: opacity 0.5s;
}*/

/* SIGN-IN FORM BUTTONS */

/*.sigin-form .ui.button.login {
	width: 100%;
}

.sigin-form .login::after {
	left: 70%;
	top: 57%;
	right: 0;
	bottom: 0;
}

.sigin-form .ui.button.register {
	margin-top: 1rem !important;
	width: 70%;
}

.sigin-form .register::after {
	left: 82%;
	top: 57%;
	right: 0;
	bottom: 0;
}

.sigin-form .gotoForgotPasswordView {
	position: relative;
	text-align: center;
	margin: 1rem auto 0;
	font-size: 1.2rem;
	cursor: pointer;
	color: var(--primary-color-dark);
}

.sigin-form .gotoForgotPasswordView:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 1px;
	bottom: 0;
	left: 0;
	background-color: var(--primary-color-dark);
	visibility: hidden;
	transform: scaleX(0);
	transition: all 0.3s ease-in-out;
}

.sigin-form .gotoForgotPasswordView:hover:before {
	visibility: visible;
	transform: scaleX(1);
}*/

/* REGISTER FORM BUTTONS */
/*.register-form .signup::after {
	left: 82%;
	top: 55%;
	right: 0;
	bottom: 0;
}

.register-form .ui.button.login {
	margin: auto;
	width: 70%;
}

.register-form .ui.button.login:hover {
	padding: 1rem 1rem 1rem 3rem !important;
}

.register-form .ui.button.login::after {
	content: "\f053";
	left: 20%;
	top: 52%;
	right: 0;
	bottom: 0;
}*/

/* FORGOTPASSWORD FORM BUTTONS */
/*.forgotPSWD-form .ui.button.requestResetLink {
	width: 100%;
	margin-bottom: 2rem;
}

.forgotPSWD-form .requestResetLink::after {
	left: 85%;
	top: 52%;
	right: 0;
	bottom: 0;
}

.forgotPSWD-form .ui.button.goToLoginView:after {
	content: "\f053";
}

.forgotPSWD-form .ui.button.goToLoginView:hover {
	padding: 1rem 1rem 1rem 3rem !important;
}

.forgotPSWD-form .ui.button.goToLoginView {
	width: 70%;
}

.forgotPSWD-form .goToLoginView::after {
	left: 10%;
	top: 52%;
	right: 0;
	bottom: 0;
}*/

/* USER ACCOUNT VERIFICATION FORM BUTTONS */

/*.userVerification-form .ui.form input {
	text-align: center;
	line-height: 4rem;
	font-size: 2rem;
	box-shadow: 0 0 5px #ccc inset;
	outline: none;
	transition: all 0.2s ease-in-out;
	padding: 2px 2px;
}

.userVerification-form .fieldErrorMsg {
	position: absolute;
	left: 0%;
	color: #9f3a38;
	width: 100%;
}

.userVerification-form .fieldSuccessMsg {
	position: absolute;
	left: 0%;
	color: #295f10;
	width: 100%;
}

.userVerification-form .ui.button.verify {
	width: 50%;
	margin: 1rem auto !important;
}

.userVerification-form .ui.button.resend:after {
	content: "\f01e";
}

.userVerification-form .ui.button.changeEmail:after {
	content: "\f053";
}

.userVerification-form .ui.button.changeEmail:hover {
	padding: 1rem 1rem 1rem 2rem !important;
}

.userVerification-form .changeEmail::after {
	left: 10%;
	top: 55%;
	right: 0;
	bottom: 0;
	font-size: 0.8rem !important;
}

.userVerification-form .resend::after,
.userVerification-form .verify::after {
	left: 82%;
	top: 57%;
	right: 0;
	bottom: 0;
}

.userVerification-form .secondaryActions {
	display: flex;
	padding: 0;
	justify-content: center;
}

.userVerification-form .secondaryActions > .ui.button {
	font-size: 1rem !important;
	width: 40%;
	margin: 0 1.5rem 0 1.5rem !important;
}*/

/* RESETPASSWORD FORM BUTTONS */
/*.resetPSWD-form .ui.button.resetPassword {
	width: 100%;
	margin-bottom: 2rem;
}

.resetPSWD-form .ui.button.resetPassword::after {
	left: 85%;
	top: 52%;
	right: 0;
	bottom: 0;
}

.welcome-form .ui.button.continueLogin {
	width: 70%;
	margin: 2rem auto;
}

.welcome-form .continueLogin::after {
	left: 85%;
	top: 57%;
	right: 0;
	bottom: 0;
}*/

/*************************************************** AppShop ****************************************************/

.AppShop > .shopPreview {
	padding: 0;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.AppShop > .shopPreview > .responsiveHeader:not(:empty) {
	z-index: 3;
}

/*.AppShop > .shopPreview > .responsiveHeader:not(:empty) {
	height: 100%;
}//commented bcoz when the shop is empty the header takes half the screen;
*/

/*.AppShop > .shopPreview .shopHeader {
	height: var(--header-height);
	flex: 0 1;
	z-index: 3;
}*/

.AppShop > .shopPreview .shopBody {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: auto;
	background: var(--bg-color);
}

.AppShop > .shopPreview .activePage {
	flex: 1;
}

.AppShop > .shopPreview .footer {
	height: var(--footer-height);
}

/*.AppShop > .shopPreview .activePage.StandardPage {
	min-height: calc(
		100% - var(--footer-height) - 2rem
	); //.AppShop > .shopPreview .activePage.StandardPage has margin-bottom : 2rem
}*/

/* Added for screens like checkout, profile, where shopbody is missing, bcoz of shopBodyscroll */
.AppShop > .shopPreview > .footer {
	background: var(--footer-background-color);
}

/* Added for react-whatsapp-widget */

.footer ._1bpcM {
	position: fixed;
	right: 5px;
	height: 55px;
	width: 55px;
	box-shadow: rgb(0 0 0 / 15%) 0px 0px 6px 2px;
	z-index: 999999;
}

.footer ._2iWL7 {
	position: fixed;
	right: 5px;
	text-align: start;
	z-index: 999999;
}

/*.expandedSearchBox > .search input,
.searchMenuItem > .search input {
	border: 1px solid rgb(228, 228, 228) !important;
	background-color: rgba(249, 249, 249) !important;
}

.expandedSearchBox > .search > .results .result .image img,
.searchMenuItem > .search > .results .result .image img {
	margin-left: auto;
}

.shopHeader .responsiveSearch {
	margin: auto;
}

.shopHeader > .expandedSearchBox {
	display: flex;
	height: 100%;
	align-items: center;
}

.shopHeader > .expandedSearchBox > .ui.fluid.search {
	margin-left: 1rem;
	flex: 1 1;
}

.shopHeader > .expandedSearchBox > .ui.fluid.search > .ui.input {
	width: 100%;
}*/

/**************** HomePage ******************/

/*.AppShop > .shopPreview > .responsiveBody.HomePageResponsiveBody:not(:empty) {
	height: 100%;
}

.AppShop > .shopPreview > .responsiveBody.fresnel-lessThan-laptop.HomePageResponsiveBody:not(:empty),
.AppShop > .shopPreview > .responsiveBody:not(.HomePageResponsiveBody):not(:empty) {
	height: calc(100% - var(--header-height));
}*/

.AppShop > .shopPreview > .responsiveBody:not(:empty) {
	height: calc(100% - var(--header-height));
}

.AppShop > .shopPreview > .responsiveBody > .pushable,
.AppShop > .shopPreview > .responsiveBody > .pushable > .pusher {
	height: 100%;
}

.responsiveBody.fresnel-lessThan-laptop .HomePage .noBannerPlaceholder {
	display: none;
}

/*.HomePage > .noBannerPlaceholder {
	width: 100%;
	height: var(--header-height);
	background: transparent;
}*/

/**************** FeaturedCollectionView ******************/

/*.featuredCollectionView {
	margin: 0;
	padding: 2rem 4rem;
}

.featuredCollectionView > .headerSection {
	display: flex;
	justify-content: space-between;
	padding: 0;
}

.featuredCollectionView .prev,
.featuredCollectionView .next {
	box-shadow: -6px -6px 10px rgba(255, 255, 255, 0.65),
		6px 6px 10px rgba(0, 0, 0, 0.125) !important;
	font-size: 1.28571429rem !important;
}

.featuredCollectionView .ui.disabled.button.prev,
.featuredCollectionView .ui.disabled.button.next {
	border: 1px solid #989898ad;
}

.featuredCollectionView .ui.button.prev:hover,
.featuredCollectionView .ui.button.next:hover {
	background-color: var(--primary-color) !important;
	color: var(--secondary-color) !important;
}

.featuredCollectionView .ui.button.prev:focus,
.featuredCollectionView .ui.button.next:focus {
	color: var(--primary-color);
	background-color: var(--secondary-color);
}

.featuredCollectionView .next {
	margin-left: 0.5rem;
}

.featuredCollectionView .ui.button.prev > .icon,
.featuredCollectionView .ui.button.next > .icon {
	opacity: 1;
}

.featuredCollectionView .ui.button.prev > .icon:before,
.featuredCollectionView .ui.button.next > .icon:before {
	vertical-align: middle;
	font-size: 1.5rem;
}

.featuredCollectionView .slick-slider .collectionImage > img {
	max-width: 100%;
	max-height: 100%;
	object-fit: cover;
	object-position: 50% 50%;
	height: 100%;
}

.featuredCollectionView .slick-slider .collectionImage {
	border-radius: 0 !important;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	background-color: #ffff !important;
}

.featuredCollectionView .slick-slider .ui.grid {
	margin: 0;
}

.featuredCollectionView .slick-slider .slick-list {
	background: white;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 1rem 0px;
	transition: all 0.3s ease-in-out;
}

.featuredCollectionView .slick-slider > .slick-arrow {
	display: none !important;
}

.featuredCollectionView .collectionInfo {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	background: var(--secondary-color);
	padding: 4rem;
	padding-bottom: 15%;
}

.collectionInfo > .ui.header.name {
	text-align: left;
	font-style: italic;
	font-weight: 400;
	font-family: var(--primary-font);
	font-size: 2.5rem;
	word-wrap: break-word;
	overflow-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-word;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

.collectionInfo .description {
	font-weight: 400;
	font-family: var(--primary-font);
	font-size: 1.2rem;
	word-wrap: break-word;
	overflow-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-word;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
	line-height: 24px;
	width: 80%;
}

.collectionInfo .description.truncate-overflow {
	--lh: 24px;
}

.featuredCollectionView .moreButton {
	position: relative;
	margin-top: 2rem !important;
	font-size: 1.5rem !important;
	width: 10rem;
	border-radius: 2rem;
	transition: padding 0.5s !important;
	padding: 1rem !important;
	box-shadow: -6px -6px 10px rgba(255, 255, 255, 0.8),
		6px 6px 10px rgba(0, 0, 0, 0.15) !important;
}

.featuredCollectionView .ui.button.moreButton::after {
	content: "\f054";
	font-family: Icons;
	font-weight: 400;
	font-size: 1rem;
	position: absolute;
	opacity: 0;
	transition: all 0.5s;
	transform: translate(-50%, -50%);
}

.featuredCollectionView .ui.button.moreButton::after {
	left: 82%;
	top: 57%;
	right: 0;
	bottom: 0;
}

.featuredCollectionView .ui.button.moreButton:hover {
	transition: all 0.5s;
	padding: 1rem 3rem 1rem 1rem !important;
}

.featuredCollectionView .ui.button.moreButton:hover::after {
	opacity: 1;
	transition: opacity 0.5s;
}*/

/************************* CollectionView ******************/

.collectionProfileView,
.collectionProfileView > .collectionSliderContainer {
	margin: 0;
	padding: 0;
}

/*.collectionProfileView > .productView {
	margin: 0;
	padding: 1rem 4rem;
}

.collectionSlider .collectionTile.ui.basic.segment {
	display: flex;
	justify-content: flex-start;
	padding: 1rem 4rem 0 3rem;
}

.collectionTile > .collectionImages {
	display: flex;
	margin-right: 4rem;
}

.collectionTile > .collectionImages > img:first-child {
	margin-left: 0;
}

.collectionTile > .collectionImages > img {
	max-width: 100%;
	max-height: 100%;
	margin-left: 0.5rem;
	object-fit: contain;
}

.collectionTile > .collectionInfo.ui.basic.segment {
	max-width: 50%;
	margin-top: 0;
}

.collectionTile
	> .collectionInfo.ui.basic.segment
	.sub.header.truncate-overflow {
	--lh: 1.2em;
}

.collectionTile
	> .collectionInfo.ui.basic.segment
	.sub.header.truncate-overflow::after {
	background: var(--secondary-color-dark);
}

.collectionSliderContainer > .actions {
	margin: 0;
	padding: 1rem 0;
	display: inline-flex;
	position: absolute;
	top: 8%;
	right: 0%;
	padding-right: 4rem;
}

.collectionSliderContainer > .actions > .ui.button {
	box-shadow: -6px -6px 10px rgba(255, 255, 255, 0.3),
		6px 6px 10px rgba(0, 0, 0, 0.1) !important;
	font-size: 1.28571429rem !important;
	margin-left: 0.5rem;
}

.collectionSliderContainer > .actions > .ui.button:hover {
	background-color: var(--primary-color) !important;
	color: var(--secondary-color) !important;
}

.collectionSliderContainer > .actions > .ui.button:focus {
	color: var(--primary-color);
	background-color: var(--secondary-color);
}

.collectionSliderContainer > .actions > .ui.disabled.button {
	border: 1px solid #989898ad;
}

.collectionSlider.slick-slider > .slick-arrow {
	display: none !important;
}

.collectionSliderContainer .slick-slider .slick-list {
	box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 1rem 0px;
}*/

/****************************************** Product Section View ******************************************/

/*.productView {
	margin: 0;
	padding: 2rem 4rem;
}

.productView > .itemContainer {
	min-height: 0rem;
	display: flex !important;
	flex-direction: column;
	flex-wrap: nowrap;
	padding-bottom: 1rem;
	padding-top: 1rem;
}*/

/* ----------- SORT BAR ----------- */

.itemContainer > .sortBar {
	height: var(--block-height);
	margin: 0rem;
	padding-right: 2.142857142857143rem;
	justify-content: flex-end;
}

.itemContainer > .sortBar > .sortView {
	display: flex;
	justify-content: flex-end;
}

/* ----------- FILTER BAR ----------- */

.itemContainer > .filterNSelectionBar {
	display: flex;
	padding: 0rem;
	margin: 0rem;
	border-bottom: 1px solid rgb(247, 244, 244) !important;
	min-height: var(--block-height);
}

/*.filterNSelectionBar .responsiveFilterBar:not(:empty),
.filterNSelectionBar .filterBar {
	flex: 1 0;
	min-height: var(--block-height);
}*/

.filterNSelectionBar .responsiveFilterBar:not(:empty) {
	flex: 1 0;
	min-height: var(--block-height);
}

.filterNSelectionBar .selectionBar {
	display: none;
}

/*.filterBar .filterView {
	justify-content: flex-start;
	flex-wrap: wrap;
	padding: 0rem 0rem 0rem 2.142857142857143rem;
	margin-left: 0rem;
	margin-bottom: 0.3rem;
}

.filterView .ui.dropdown > .menu > .header {
	font-size: 0.88571429em;
	font-weight: 500;
}

.filterView .menu > .item > .filterOptionCount {
	margin-right: 0rem !important;
	background-color: var(--secondary-color);
	color: var(--primary-color-dark);
	transition: background-color 0.5s ease;
	min-width: 2.2em;
}

.filterView .menu > .item:hover > .filterOptionCount {
	background-color: white;
}

.filterView .menu > .selected.item > .filterOptionCount {
	margin-right: 0rem !important;
	background-color: white;
	color: var(--primary-color-dark) !important;
}

.filterView .ui.dropdown .menu > .item {
	border-bottom: 1px solid #dcdbdb;
	padding-right: 0.54285714rem !important;
	padding-left: 0.64285714rem !important;
}

.filterView .menu > .selected.item {
	background-color: var(--secondary-color) !important;
	color: var(--primary-color-dark) !important;
}

.filterView > .filter {
	margin-right: 2rem;
	display: inline-flex;
}

.filterBar .filterView > .filter .filterOption {
	display: flex;
	justify-content: space-between;
}

.filterView > .filter .dropdown.icon {
	font-size: 1.3em;
	margin-left: 0.5rem !important;
}

.filterView > .filter > .selectedFilter > .text,
.filterView > .filter > .selectedFilter > .dropdown.icon {
	color: var(--primary-color-dark);
}

.filterView > .filter.extra {
	display: inline-block;
	cursor: pointer;
	font-size: 1rem;
}

.filterBar .selectedFilterView {
	padding: 0rem 0rem 0rem 2.14286rem;
}

.filterBar .selectedFilterView > .selectedFilter {
	border: none;
	font-weight: 400;
	background-color: var(--secondary-color-dark);
	border-radius: 1.5rem;
}*/

/* Category Dropdown Look 'N' Feel */

.ui.dropdown .menu > .categoryOption.Level1 {
	border-top: 1px solid #dcdbdb;
	border-bottom: 1px solid #dcdbdb;
	font-weight: 400;
	font-size: 1.3em;
}

.ui.dropdown .menu > .item.filterOption.Level1 {
	padding: 0.38571429rem 0.54285714rem 0.38571429rem 0.64285714rem !important;
}

.categoryOption.Level2 {
	border-bottom: 1px solid #dcdbdbc7 !important;
	font-size: 1.1em !important;
	font-weight: 400 !important;
}

.ui.dropdown .menu > .item.filterOption.Level2 {
	padding: 0.28571429rem 0.54285714rem 0.28571429rem 0.64285714rem !important;
}

.categoryOption.Level3 {
	border-bottom: 1px solid #dcdbdb8c !important;
	font-size: 0.9em !important;
	font-weight: 400 !important;
}

.ui.dropdown .menu > .item.filterOption.Level3 {
	padding: 0.18571429rem 0.54285714rem 0.18571429rem 0.64285714rem !important;
}

.categoryOption.Level4 {
	border-bottom: 1px solid #dcdbdb5e !important;
	font-size: 0.8em !important;
	font-weight: 400 !important;
}

.ui.dropdown .menu > .item.filterOption.Level4 {
	padding: 0.10571429rem 0.54285714rem 0.10571429rem 0.64285714rem !important;
}

.filterOption.categoryOption.Level1:first-child {
	border-top: none !important;
}

.categoryOption.bottomBorderNone {
	border-bottom: none !important;
}

.categoryOption.topBorder {
	border-top: 1px solid #dcdbdb !important;
}

.categoryOption.Level1:last-child {
	border-bottom: 1px solid #dcdbdb !important;
}

/* ----------- FILTER View - Mobile ----------- */

.ui.sidebar.filterMenuSideBar {
	border: none !important;
}

/*.filterMobileView {
	width: 100%;
}

.filterMobileView > .filterHeader {
	position: sticky;
	top: 0;
	display: flex;
	justify-content: space-between;
	margin-bottom: 0;
	border-bottom: 1px solid #d4d4d5 !important;
	z-index: 2;
	background: white !important;
	height: var(--block-height);
	padding-left: 1.5rem;
}

.filterMobileView > .filterHeader > .header {
	margin: 0;
}

.filterMobileView > .ui.grid.filterBody {
	margin: -1rem 0 -1rem -1rem;
	min-height: calc(100% + 0.5rem + var(--block-height) - var(--footer-height));
}*/

/*.filterMobileView > .ui.grid.filterBody > .column:not(.row){
      padding-bottom: 0;
    }*/

/*.filterMobileView > .ui.grid.filterBody .filterMenuList {
	background-color: var(--secondary-color);
}

.filterMobileView > .ui.grid.filterBody .filterMenuList > .filterMenuItem {
	min-height: var(--block-height);
	border-left: none;
	border-radius: 0 !important;
	border-bottom: 1px solid var(--secondary-color-dark);
	line-height: 1.5;
	word-wrap: break-word;
	overflow-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-word;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.filterMobileView
	> .ui.grid.filterBody
	.filterMenuList
	> .filterMenuItem
	> .icons {
	line-height: 2;
}

.filterMobileView
	> .ui.grid.filterBody
	.filterMenuList
	> .filterMenuItem
	> .icons
	> .corner.icon {
	line-height: 0;
}

.filterMobileView
	> .ui.grid.filterBody
	.filterMenuList
	> .filterMenuItem.active {
	border-top: none;
}

.filterMobileView > .filterBody .filterOptionSearchBar {
	height: var(--block-height);
	padding: 0.5rem 0 0 0;
}

.filterMobileView > .filterBody .filterOptionSearchBar > .input.search {
	height: 100%;
}

.filterMobileView > .filterBody .filterOptionSearchBar > .input.search > input {
	border-radius: 4rem;
}

.filterMobileView > .filterBody .filterOptionList {
	margin-top: 0;
}

.filterMobileView > .filterBody .filterOptionList > .filterOption {
	display: flex !important;
	min-height: var(--block-height);
	font-size: 1rem;
}

.filterMobileView > .filterBody .filterOptionList > .filterOption.item > .icon {
	opacity: 0.2;
}

.filterOptionList > .filterOption.item > .content {
	line-height: 2;
	word-wrap: break-word;
	overflow-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-word;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

.filterOptionList > .filterOption.selected > .icon {
	opacity: 1 !important;
	color: var(--primary-color-light);
}

.filterOptionList > .filterOption.selected > .content {
	font-weight: 700 !important;
}

.filterOptionList > .filterOption.selected > .content > .filterOptionCount {
	background-color: var(--primary-color-light);
	color: white;
}*/

/*.filterMobileView > .filterFooter {
	position: sticky;
	bottom: 0px;
	z-index: 2;
	background: white !important;
	width: 100%;
	height: var(--block-height);
}

.filterMobileView > .filterFooter.ui.basic.buttons > .primary.button {
	color: var(--primary-color-dark) !important;
}*/

/*.filterMobileView > .filterBody .stickyItem {
	position: sticky;
	top: var(--block-height);
	z-index: 2;
}

.filterMobileView > .filterBody .stickySibling {
	height: 100%;
}*/

/* Category Dropdown Look 'N' Feel */

.ui.list.filterOptionList > .filterOption.categoryFilterOption.Level1 > .content {
	font-weight: 400;
	font-size: 1.3em;
}

.ui.list.filterOptionList > .filterOption.categoryFilterOption.Level2 > .content {
	font-size: 1.1em;
	font-weight: 400;
}

.ui.list.filterOptionList > .filterOption.categoryFilterOption.Level3 > .content {
	font-size: 0.9em;
	font-weight: 400;
}

.ui.list.filterOptionList > .filterOption.categoryFilterOption.Level4 > .content {
	font-size: 0.8em;
	font-weight: 400;
}

/* ----------- CONTENT CONTAINER ----------- */

/*.itemContainer > .itemContentContainer {
	overflow-y: auto;
	padding: 0rem 2.142857142857143rem 0rem 2.142857142857143rem;
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	margin-left: -1rem;
}

.itemContentContainer > .cardView {
	height: auto;
	flex-grow: 1;
	position: relative;
	margin: 0 1rem 0 0; /* added to adjust the bottom border added for selected card*/
/*}

.cardView > .cards {
	padding: 0.1em;
	margin: 0 !important;
}

.cardView .card {
	border-radius: 0 0 0.28571429rem 0.28571429rem !important;
}

.cardView .card:hover {
	transform: none !important;
}

.cardView .card > .profile {
	position: relative;
	height: 79%;
}

.cardView .card > .content {
	border-top: 0 !important;
	height: 20%;
}

.cardView .card > .profile > .image {
	border-radius: 0 !important;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	background-color: #ffff !important;
}

.cardView .card > .profile > .image > img {
	max-width: 100%;
	max-height: 100%;
}

.cardView .card > .profile > .image.imgContain > img {
	object-fit: contain;
	object-position: 50% 50%;
}

.cardView .card > .profile > .image.imgCover > img {
	object-fit: cover;
	object-position: 0% 0%;
	height: 100%;
}

.card .itemLabel {
	font-family: var(--primary-font) !important;
	font-size: 1.2rem !important;
	font-weight: 400 !important;
	font-style: normal !important;
	line-height: 1.2;
	color: #605c5c;
	margin: 1rem 0 0.5rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.card .itemCategory.ui.basic.label {
	font-weight: 500;
	color: var(--primary-color-darker) !important;
	background: var(--secondary-color) !important;
	box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.8),
		3px 3px 5px rgba(0, 0, 0, 0.2) !important;
}

.card .itemStock.ui.label {
	font-weight: 500;
	box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.8),
		3px 3px 5px rgba(0, 0, 0, 0.2) !important;
}

.card .itemDesc {
	font-family: var(--primary-font) !important;
	font-size: 1rem !important;
	font-weight: 500 !important;
	font-style: normal !important;
	color: rgb(154, 153, 153) !important;
	overflow: hidden;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.card .productPrice {
	line-height: 0.9375rem;
	margin: 0.5rem 0 0.5rem;
}

.productPrice .product-discountPercentage {
	color: var(--primary-color-dark);
	font-weight: 400;
	font-size: 1rem;
	display: inline-block;
}

.productPrice .product-discountedPrice {
	font-size: 1.2rem;
	color: #282c3f;
	font-weight: 500;
	display: inline-block;
	margin-right: 0.3125rem;
}

.productPrice .product-retailPrice {
	text-decoration: line-through;
	color: #7e818c;
	font-weight: 400;
	margin-right: 0.3125rem;
	font-size: 1rem;
	display: inline-block;
}

.card .itemType {
	font-family: var(--primary-font) !important;
	font-size: 0.7857142857142857rem !important;
	font-weight: 400 !important;
	font-style: normal !important;
	color: #605c5c !important;
}*/

/* ----------- PAGINATION ----------- */

/*.paginationView {
	margin: 0 !important;
	text-align: center;
	flex-shrink: 0;
}

.paginationView > .pagination {
	background-color: rgb(243, 241, 241) !important;
	margin-bottom: -0.9285714285714286rem !important;
}*/

/************************************************************ Product Profile View **********************************************************************/

/*.productProfileView {
	padding: 2.5rem;
}

.productProfileView > .openViewGrid {
	margin: 0;
}

.productProfileView .ui.grid.openViewGrid > .row > .column.imageColumn {
	padding: 0 !important;
}

.productProfileView .imageGallery > .imageRow {
	display: flex;
}

.productProfileView .imageGallery > .imageRow:first-child > .imageView {
	margin-top: 0 !important;
}

.productProfileView .imageGallery > .imageRow > .imageView {
	flex: 1;
	margin: 0.5rem;
	padding: 0;
	overflow: hidden;
}

.productProfileView .imageGallery > .imageRow > .imageView img {
	transition: transform 0.5s ease;
}

.productProfileView .imageGallery > .imageRow > .imageView:hover img {
	transform: scale(1.25);
}

.productProfileView .imageGallery .slick-slider > .slick-arrow {
	display: none !important;
}

.imageGallery .imageView > img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.productInfoColumn .priceBlock > .discountedPrice {
	font-size: 1.8rem;
	font-weight: 500;
	display: inline-block;
	margin-right: 1rem;
}

.productInfoColumn .priceBlock > .retailPrice {
	font-size: 1.8rem;
	font-weight: 300;
	margin-right: 1rem;
	text-decoration: line-through;
	color: #76767694;
	display: inline-block;
}

.productInfoColumn .priceBlock > .discountPercentage {
	font-size: 1.5rem;
	font-weight: 300;
	color: var(--primary-color-dark);
	display: inline-block;
}

.productInfoColumn .sizeBlock > .sizeHeader {
	display: inline-flex;
	margin: 1rem 0;
	height: 2.25rem;
}

.productInfoColumn .sizeBlock .ui.label.selectSizeWarning {
	background-color: var(--secondary-color-darker);
	color: white;
	box-shadow: -6px -6px 10px rgba(255, 255, 255, 0.8),
		6px 6px 10px rgba(0, 0, 0, 0.15);
}

.productInfoColumn .sizeBlock .ui.labels .sizeLabel {
	min-width: 2.25em;
	min-height: 2.25em;
	line-height: 1em;
	box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.8),
		3px 3px 5px rgba(0, 0, 0, 0.2);
	z-index: 2;
	transition: all 0.5s;
}

.productInfoColumn .sizeBlock .ui.labels .sizeLabel.disabled {
	box-shadow: none !important;
}

.productInfoColumn .sizeBlock .ui.labels .sizeLabel:not(.selected):hover {
	cursor: pointer;
	box-shadow: none !important;
}

.productInfoColumn .sizeBlock .ui.labels .sizeLabel.selected {
	color: var(--primary-color-dark);
	border-color: var(--primary-color-dark);
}*/

/*.productInfoColumn .ui.button.outOfStock,
.productInfoColumn .ui.button.goToCart,
.productInfoColumn .ui.button.addToCart {
	position: relative;
	margin-top: 0.5rem !important;
	display: block !important;
	box-shadow: -10px -10px 8px rgba(255, 255, 255, 0.9),
		8px 8px 12px rgba(0, 0, 0, 0.25) !important;
	font-size: 1.25rem !important;
	transition: padding 0.5s !important;
	padding: 1rem !important;
	min-width: 40%;
}

.productInfoColumn .ui.button.outOfStock.disabled {
	color: red !important;
}*/

/*.productInfoColumn .ui.button.goToCart:hover,
.productInfoColumn .ui.button.addToCart:hover {
	transition: all 0.5s;
	padding: 1rem 3rem 1rem 1rem !important;
}*/

/*.productInfoColumn .ui.button.addToCart::after {
	content: "\f217";
	font-family: Icons;
	font-weight: 400;
	font-size: 1rem;
	position: absolute;
	opacity: 0;
	transition: all 0.5s;
	transform: translate3d(-50%, -50%, 0);
}

.productInfoColumn .addToCart::after {
	left: 82%;
	top: 57%;
	right: 0;
	bottom: 0;
}

.productInfoColumn .ui.button.addToCart:hover::after {
	opacity: 1;
	transition: opacity 0.5s;
}

.productInfoColumn .ui.button.goToCart::before {
	content: "\f30b";
	font-family: Icons;
	font-weight: 400;
	font-size: 1rem;
	position: absolute;
	opacity: 0;
	transition: all 0.5s;
	transform: translate3d(-50%, -50%, 0);
	left: 75%;
	top: 57%;
	right: 0;
	bottom: 0;
}

.productInfoColumn .ui.button.goToCart::after {
	content: "\f07a";
	font-family: Icons;
	font-weight: 400;
	font-size: 1rem;
	position: absolute;
	opacity: 0;
	transition: all 0.5s;
	transform: translate3d(-50%, -50%, 0);
	left: 90%;
	top: 57%;
	right: 0;
	bottom: 0;
}

.productInfoColumn .ui.button.goToCart:hover::before,
.productInfoColumn .ui.button.goToCart:hover::after {
	opacity: 1;
	transition: opacity 0.5s;
}

.ui.message.addedToCartSuccessMsg {
	position: absolute;
	top: 10%;
	right: 1%;
	background-color: var(--secondary-color-darker);
	color: white;
	box-shadow: -6px -6px 10px rgba(255, 255, 255, 0.8),
		6px 6px 10px rgba(0, 0, 0, 0.15);
}

.ui.message.addedToCartSuccessMsg > .content {
	padding-right: 0.5rem;
}*/

/************************************************************ Cart View **********************************************************************/

.ui.label.cartItemCountBubble {
	font-size: 0.7rem;
	top: -1.1rem;
	left: 85%;
	font-weight: 800;
	font-family: "Lato";
}

/*.ui.modal.cartPopup > .content {
	background-color: var(--secondary-color);
	padding: 0;
}

.ui.modal.cartPopup .scrolling.content {
	max-height: calc(60vh);
}

.ui.modal.cartPopup > .actions {
	text-align: center;
	background-color: white;
}

.ui.modal.cartPopup > .actions > .checkoutSecureLabel {
	margin-bottom: 1rem;
	color: var(--primary-color);
}

.ui.modal.cartPopup > .actions > .cartSummary {
	display: flex;
	justify-content: space-between;
	height: 3rem;
	padding: 0 1.7rem 0 1rem;
}

.ui.modal.cartPopup > .actions > .cartSummary > * {
	margin: 0;
}*/

/*.ui.modal.cartPopup > .actions > .cartCheckout {
	position: relative;
	font-size: 1rem !important;
	width: 12rem;
	transition: padding 0.5s !important;
	padding: 1rem !important;
	box-shadow: -6px -6px 11px rgba(255, 255, 255, 1),
		6px 6px 11px rgba(0, 0, 0, 0.25) !important;
}

.ui.modal.cartPopup > .actions > .cartCheckout::after {
	content: "\f054";
	font-family: Icons;
	font-weight: 400;
	font-size: 1rem;
	position: absolute;
	opacity: 0;
	transition: all 0.5s;
	transform: translate(-50%, -50%);
	left: 82%;
	top: 57%;
	right: 0;
	bottom: 0;
}

.ui.modal.cartPopup > .actions > .cartCheckout:hover {
	transition: all 0.5s;
	padding: 1rem 3rem 1rem 1rem !important;
}

.ui.modal.cartPopup > .actions > .cartCheckout:hover::after {
	opacity: 1;
	transition: opacity 0.5s;
}

.ui.segment.cartView {
	padding: 0;
}

.ui.segment.cartView > .orderList {
	padding: 2rem 2rem 0.5rem 2rem;
}

.ui.segment.orderItem {
	margin-bottom: 1.5rem;
}

.orderItem > .ui.items {
	margin: 0;
}

.orderItem > .orderItemActions {
	padding: 0;
	margin-top: 0;
	display: flex;
}

.orderItem .ui.compact.segment.productPrice {
	display: inline-grid;
	margin: 0;
	padding: 0;
}

.orderItem .item > .content > .header.productLabel {
	font-size: 1rem !important;
}

.orderItem .item > .content > .description.productDescription {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 80%;
}

.orderItem .item > .content > .meta.productCategory {
	color: var(--secondary-color-darker);
}

.orderItemActions > .removeAction {
	cursor: pointer;
	border-right: 1px solid var(--secondary-color-darkest);
	padding-right: 1rem;
	color: var(--secondary-color-darkest);
	font-weight: 600;
}

.orderItemActions > .wishListAction {
	cursor: pointer;
	padding-left: 1rem;
	color: var(--secondary-color-darkest);
	font-weight: 600;
}

.quantityDropdown.ui.dropdown > .menu.transition {
	left: -1rem;
	top: 1.2rem;
}*/

/****************************** Order View *****************************/

/*.orderDetailView {
	padding: 0.5rem 2rem;
}*/

/****************************** Order Vertical Timeline *****************************/

/*.vertical-timeline.orderTimeline {
	padding: 0;
}

.orderTimeline.vertical-timeline::before {
	top: 2em;
}

.orderTimelineEvent .vertical-timeline-element-date {
	display: none;
}

.trackDivider.ui.divider,
.orderItemDivider.ui.divider {
	text-transform: none !important;
	margin-top: 1.5rem;
}

.trackDivider.ui.horizontal.divider:before,
.orderItemDivider.ui.horizontal.divider:before {
	width: 0%;
}

.trackDivider.ui.horizontal.divider:after,
.orderItemDivider.ui.horizontal.divider:after {
	width: 100%;
}*/

/****************************** OrderHistory Timeline *****************************/

.flowContainer {
	display: flex;
	justify-content: space-between;
	margin-left: 2rem !important;
	margin-right: 2rem !important;
}

.flow {
	display: inline-block;
	flex: 1 1 33.3333%;
}

.flow.start {
	flex: 0 1 0% !important;
	width: 0.8rem;
}

.flow > .dateLabel {
	display: block;
	text-align: center;
	transform: translate(calc(50% - 0.4rem), 20%);
	font-size: 0.8rem;
}

.flow > .dateLabel.done {
	font-weight: 600;
}

.flow > .dateLabel.done.active {
	color: black;
}

.orderDelivered .flow.end > .dateLabel.done {
	color: #039e24;
}

.orderReturned .flow.end > .dateLabel.done {
	color: orange;
}

.orderCancelled .flow.end > .dateLabel.done {
	color: red;
}

.flow.start > .dateLabel {
	transform: translate(calc(-100% + 3rem), 20%);
	width: 6rem;
}

.flow > .stageLabel {
	display: block;
	text-align: center;
	transform: translate(calc(50% - 0.4rem), -50%);
	font-weight: 600;
	font-size: 0.8rem;
}

.flow > .stageLabel.done {
	color: #039e24;
}

.orderDelivered .flow.end > .stageLabel.done {
	color: #039e24;
}

.orderReturned .flow.end > .stageLabel.done {
	color: orange;
}

.orderCancelled .flow.end > .stageLabel.done {
	color: red;
}

.flow.start > .stageLabel {
	transform: translate(calc(-100%), -50%);
}

.flow > .dot {
	display: block;
	width: 0.8rem;
	height: 0.8rem;
	border: 1px solid #8e8e93;
	border-radius: 50%;
	margin-left: calc(100% - 0.8rem);
}

.flow > .dot.done {
	background: #039e24;
	border-color: #11e23e;
}

.orderDelivered .flow.end > .dot.done {
	background: #039e24;
	border-color: #11e23e;
}

.orderReturned .flow.end > .dot.done {
	background: orange;
	border-color: rgb(184, 120, 3);
}

.orderCancelled .flow.end > .dot.done {
	background: red;
	border-color: red;
}

.flow > .bar {
	color: #575757;
	position: relative;
	width: calc(100% - 0.8rem);
}

.flow > .bar::after {
	content: "";
	top: -0.45rem;
	left: 0;
	width: 100%;
	border-top-width: 2px;
	border-top-style: solid;
	border-color: #c5c6c7;
	position: absolute;
	box-sizing: border-box;
}

.flow > .bar.done::after {
	border-color: #039e24;
}

/********************************* Checkout View **************************************/

/*.AppShop > .shopPreview .shopCheckoutBody {
	flex: 1 0;
	height: 100%;
	overflow: auto;
	background: var(--secondary-color);
	display: flex;
	flex-direction: column;
}

.shopCheckoutBody > .checkoutView {
	margin: 0rem !important;
	overflow: auto;
	flex: 1 0;
}

.shopCheckoutBody > .footer {
	flex: 0 1;
	height: auto !important;
}

.checkoutView .payNow,
.checkoutView .continue {
	position: relative;
	font-size: 1rem !important;
	width: 100%;
	transition: padding 0.5s !important;
	padding: 1rem !important;
	box-shadow: -6px -6px 11px rgba(255, 255, 255, 1),
		6px 6px 11px rgba(0, 0, 0, 0.25) !important;
}

.checkoutView .payNow::after,
.checkoutView .continue::after {
	content: "\f054";
	font-family: Icons;
	font-weight: 400;
	font-size: 1rem;
	position: absolute;
	opacity: 0;
	transition: all 0.5s;
	transform: translate(-50%, -50%);
	left: 82%;
	top: 57%;
	right: 0;
	bottom: 0;
}

.checkoutView .payNow:hover,
.checkoutView .continue:hover {
	transition: all 0.5s;
	padding: 1rem 3rem 1rem 1rem !important;
}

.checkoutView .payNow:hover::after,
.checkoutView .continue:hover::after {
	opacity: 1;
	transition: opacity 0.5s;
}

.checkoutView .addNewAddress {
	position: relative;
	font-size: 1rem !important;
	width: 12rem;
	transition: padding 0.5s !important;
}

.checkoutView .ui.button.addNewAddress:hover {
	transition: all 0.5s;
	padding-left: 2rem !important;
}

.checkoutView .ui.button.addNewAddress::after {
	content: "\f067";
	font-family: Icons;
	font-weight: 400;
	font-size: 0.8rem;
	position: absolute;
	opacity: 0;
	transition: all 0.5s;
	transform: translate(-50%, -50%);
	left: 10%;
	top: 50%;
	right: 0;
	bottom: 0;
}

.checkoutView .ui.button.addNewAddress:hover::after {
	opacity: 1;
	transition: opacity 0.5s;
}

.checkoutView .ui.button.deliverHere {
	position: relative;
	width: 9rem;
	font-size: 1rem !important;
	transition: padding 0.5s !important;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
}

.checkoutView .ui.button.deliverHere:hover {
	transition: all 0.5s;
	padding-left: 2rem !important;
}

.checkoutView .ui.button.deliverHere:not(.loading):before {
	content: "\f3c5";
	font-family: Icons;
	font-weight: 400;
	font-size: 0.8rem;
	position: absolute;
	opacity: 0;
	transition: all 0.5s;
	transform: translate(-50%, -50%);
	left: 10%;
	top: 50%;
	right: 0;
	bottom: 0;
}

.checkoutView .ui.button.deliverHere:not(.loading):hover::before {
	opacity: 1;
	transition: opacity 0.5s;
}

/*.checkoutView .notifyAddAddress {
	position: absolute;
	margin: auto;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.checkoutView > .grid > .priceSummaryView.stickyItem {
	height: 100%;
	position: sticky;
	top: -7rem;
}

.checkoutView > .grid > .priceSummaryView > div {
	margin-top: 7.15rem;
}*/

/********* checkoutTimeline *********/

/*.checkoutTimeline {
	display: flex;
	justify-content: space-evenly;
	width: 80%;
	margin: 1rem auto !important;
	height: 5rem;
}

.connector {
	display: inline-block;
	flex: 1 1;
}

.checkpoint {
	flex: 0 1;
	text-align: center;
	margin: auto;
}

.checkpoint > .point {
	position: relative;
	font-size: 1rem !important;
	width: 12rem;
	border-radius: 2rem;
	transition: padding 0.5s !important;
}

.checkpoint > .point.primary {
	box-shadow: -6px -6px 11px rgba(255, 255, 255, 1),
		6px 6px 11px rgba(0, 0, 0, 0.25) !important;
}

.checkpoint > .point.secondary {
	box-shadow: -6px -6px 10px rgba(255, 255, 255, 0.8),
		6px 6px 10px rgba(0, 0, 0, 0.15) !important;
}

.checkpoint > .ui.button:disabled.point {
	box-shadow: -6px -6px 10px rgba(255, 255, 255, 0.8),
		6px 6px 10px rgba(0, 0, 0, 0.15) !important;
}

.checkpoint > .ui.button.secondary.point:hover::after {
	opacity: 1 !important;
	transition: opacity 0.5s;
}

.checkpoint.orderSummary > .ui.button.secondary.point:hover {
	transition: all 0.5s;
	padding-right: 3rem !important;
}

.checkpoint.orderSummary > .ui.button.secondary.point::after {
	content: "\f054";
	font-family: Icons;
	font-weight: 400;
	font-size: 0.8rem;
	position: absolute;
	left: 82%;
	top: 51%;
	right: 0;
	bottom: 0;
	opacity: 0;
	transition: all 0.5s;
	transform: translate(-50%, -50%);
}

.checkpoint.address > .ui.button.secondary.point:hover {
	transition: all 0.5s;
	padding-left: 3rem !important;
}

.checkpoint.address > .ui.button.secondary.point::after {
	content: "\f053";
	font-family: Icons;
	font-weight: 400;
	font-size: 0.8rem;
	position: absolute;
	left: 25%;
	top: 54%;
	right: 0;
	bottom: 0;
	opacity: 0;
	transition: all 0.5s;
	transform: translate(-50%, -50%);
} 

.connector > .line {
	position: relative;
	width: 90%;
	height: 100%;
	margin: auto;
}

.connector > .line::after {
	content: "";
	top: calc(50% - 1px);
	left: 0;
	width: 100%;
	border-top-width: 2px;
	border-top-style: dotted;
	border-color: var(--secondary-color-darker);
	position: absolute;
	box-sizing: border-box;
}

.connector > .line.active::after {
	border-color: var(--primary-color-dark) !important;
} */

/****************************************************** Title And Description ******************************************************/

.AppShop > .shopPreview .activePage.StandardPage {
	display: flex;
	flex-direction: column;
	margin-bottom: 2rem;
}

.ql-editor {
	word-break: break-word;
}

/*.activePage.StandardPage > .titleAndDescriptionSection {
	flex: 1;
	margin-top: 4rem;
	padding: 1rem;
	text-align: center;
}*/

/****************************************************** Payment Screen ******************************************************/

.payment-screen {
	height: 100%;
}
/*.paymentScreen {
	height: 100%;
	display: flex !important;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: var(--secondary-color);
}

.paymentScreen > .paymentView {
	max-width: 40%;
	margin: 0;
	padding: 0;
}*/

/****************************************************** SHOP HOME PAGE BANNER ******************************************************/

/*.shopPreview .shopHeader.homeHeader {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
}

.shopPreview .shopHeader.transparent {
	background: transparent;
	box-shadow: none;
	backdrop-filter: blur(5px);
}

.shopPreview .shopHeader.transparent .headerMenu > .menu > .active.item {
	color: white !important;
}

.shopPreview .shopHeader.transparent .headerMenu > .menu > .item {
	color: var(--secondary-color-dark) !important;
}*/

/*.bannerHeader {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	overflow: hidden;
	transition: all 0.7s;

	background-color: #1f1f1f;
	height: 100vh;
	background-attachment: fixed;
}

.bannerHeader::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #1f1f1f;
	opacity: 0.5;
	box-sizing: border-box;
}*/

.bannerHeader .scrollDown {
	font-size: 18px;
	letter-spacing: 1px;
	color: var(--secondary-color-dark);
	writing-mode: vertical-rl;
	text-orientation: mixed;
	position: absolute;
	top: calc(var(--header-height) + 5%);
	right: 3.5%;
	padding-bottom: 73px;
	display: block;
	z-index: 999;
}

.bannerHeader .scrollDown:hover {
	color: #fff;
	cursor: pointer;
}

.bannerHeader .scrollDown::after {
	content: "";
	position: absolute;
	width: 10px;
	height: 55px;
	background: url(../../../images/arrow.svg) center no-repeat;
	background-size: contain;
	display: block;
	margin: auto;
	display: block;
	bottom: 0;
	left: 0;
	right: 0;
	transition: all 0.3s;
}

.bannerHeader .scrollDown:hover::after {
	bottom: -10px;
}

.bannerHeader .bannerSocialBar {
	position: absolute;
	bottom: 10%;
	left: 3%;
}

/*Added to support content blend mode where color for social icon 
will come from styled banner header */

.bannerHeader .ui.list > a.item {
	color: inherit;
}

.bannerHeader .ui.list > a.item i.icon.bannerSocialIcon {
	color: inherit;
	font-size: 2.5rem;
	margin-top: 2rem;
}

.bannerHeader .ui.list.bannerSocialBar > a.item:hover i.icon.bannerSocialIcon {
	color: white;
}

/****************************************************** BANNER Section ******************************************************/

/*.bannerSection > .bannerHeader {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	overflow: hidden;
	transition: all 0.7s;
	background-color: #1f1f1f;
	height: 60vh;
	background-attachment: fixed;
}

.bannerSection > .bannerHeader::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #1f1f1f;
	opacity: 0.7;
	box-sizing: border-box;
}

.bannerSection > .bannerHeader > .bannerTitle {
	font-size: 5rem;
	color: var(--secondary-color);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
	text-align: center;
}

.bannerSection > .bannerHeader > .bannerTitle > .bannerTagline {
	font-size: 1.5rem;
	color: var(--secondary-color-dark);
}*/

/****************************************************** Contact US ******************************************************/

.StandardPage .bannerSection + .contactUsSection {
	margin-top: -5%;
	margin-bottom: 5%;
}

.StandardPage .bannerSection + .contactUsSection > .header {
	display: none;
}

/*.StandardPage .contactUsSection {
	font-family: var(--primary-font);
	margin-top: 4rem;
}

.StandardPage .contactUsSection > .ui.grid.contactUsInfo {
	margin: auto;
	padding: 1rem;
}

.contactUsInfo .info .icon {
	background-color: var(--secondary-color-dark);
	color: black;
	box-shadow: -3px -3px 5px rgb(255 255 255), 3px 3px 5px rgb(0 0 0 / 25%) !important;
}

.contactUsInfo .ui.items > .item > .content {
	margin: 0.5rem 1rem;
}

.contactUsInfo .infoForm,
.contactUsInfo .info {
	padding: 2rem 2rem;
	height: 100%;
}

.contactUsInfo .info > .ui.items {
	display: flex;
	flex-direction: column;
}

.contactUsInfo .info > .form {
	width: 90%;
	margin: auto;
}*/

/****************************************************** Address Form ******************************************************/

/*.addressForm.ui.form .field {
	position: relative;
	margin-bottom: 2rem;
}

.addressForm textarea,
.addressForm input {
	color: black;
	background: #ffffff;
	font-family: var(--primary-font) !important;
}

.addressForm.ui.form .field textarea:not(:last-child),
.addressForm.ui.form .field > .ui.input.error > input {
	border-color: #e0b4b4 !important;
	color: #9f3a38 !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

.addressForm.ui.form .field .fieldErrorMsg {
	position: absolute;
	right: 2%;
	color: #9f3a38;
	bottom: -1.5rem;
}

.addressForm.ui.form .error.error textarea:-webkit-autofill:focus,
.addressForm.ui.form .error.error textarea:-webkit-autofill,
.addressForm.ui.form .error.error input:-webkit-autofill:focus,
.addressForm.ui.form .error.error input:-webkit-autofill {
	background-color: #fff6f6 !important;
	-webkit-box-shadow: 0 0 0 100px #fffaf0 inset !important;
	box-shadow: 0 0 0 100px #fffaf0 inset !important;
	border-color: #e0b4b4 !important;
}*/

/**************** AccountPage ******************/

.AppShop > .shopPreview .shopBody > .AccountPage > .responsiveAccountPage:not(:empty) {
	height: 100%;
}

/*********************************************************Reviews and Ratings(Widget Pack)********************************************************/

.review > .wpac {
	margin-left: 1.5rem !important;
	margin-right: 3rem !important;
	padding: 1rem 2rem !important;
	border-radius: 8px !important;
	background-color: white !important;
}

.wpac .wp-addbtn > .wp-btn {
	border-radius: 8px !important;
	width: 10rem !important;
}

.review {
	font: var(--primary-font) !important;
}

.review > .wp-rw .wpac > .wp-menu > .wp-rightrow .wp-logo {
	display: none !important;
}

.review .wpac .wp-item .wp-item-text {
	font-family: var(--secondary-font) !important;
}

.review .wpac .wp-item .wp-item-text {
	border: none !important;
	/* outline: 1px solid #ccc!important; */
}

.review-header {
	height: 3rem;
	display: flex;
	align-items: center;
	margin-bottom: 2rem;
	margin-left: 1.5rem;
	border-bottom: 1px solid #ccc;
	font-family: var(--primary-font);
	margin-right: 3rem;
	font-size: 1rem;
	font-weight: 700;
}

.wp-modal-cnt.wpac .wp-l .wp-auth-anonym input {
	border: none !important;
	outline: 1px solid #ccc !important;
}

.wp-modal-cnt.wpac .wp-comment {
	padding: 0.5rem 1rem !important;
	margin-top: 1rem !important;
}

.wp-modal-cnt.wpac .wp-modal-title {
	font-family: var(--primary-font) !important;
}

.wp-modal-cnt.wpac .wp-l .wp-auth-anonym input {
	padding-left: 0.8rem !important;
}

/********************************************* React Quill *********************************/

.ui.basic.segment > .ql-editor > blockquote {
	margin-bottom: 5px;
	margin-top: 5px;
	border-left: 4px solid #ccc;
	padding-left: 5px;
}

/********************************************* MY Orders Cancel  *********************************/

.ui.tiny.modal.transition.visible.active.zoom.visible.transition .content > .ui.basic.segment {
	padding-bottom: 0em;
}
.ui.tiny.modal.transition.visible.active.zoom.visible.transition .content {
	padding-bottom: 0em;
}

.content > .ui.basic.segment .ui.header.cancelHeader .content {
	font-weight: 400;
}

.ui.basic.segment .ui.ui.basic.segment > label {
	margin-bottom: 0em;
}

.ui.basic.segment .ui.ui.basic.segment > label {
	padding-right: 2em;
	padding-left: 0em;
}

.ui.basic.segment .ui.ui.basic.segment {
	padding-left: 0em;
	padding-right: 0rem;
}
.ui.basic.segment .ui.ui.basic.segment .ui.selection.dropdown {
	min-width: 20.8em;
}
