@media only screen and (max-width: 319px) {
	/* smaller than 320px size */

	.pageNotFound > .msg > .msgBox > .header {
		height: 11.571428571428571rem !important;
	}

	.pageNotFound > .msg > .msgBox > .header > h1 {
		font-size: 11.571428571428571rem !important;
		height: 10.714285714285714rem !important;
		line-height: 11.571428571428571rem !important;
	}

	.pageNotFound > .msg > .msgBox > h2 {
		font-size: 1rem !important;
	}
}

@media only screen and (min-width: 320px) {
	/* greater than 320px size */

	.pageNotFound > .msg > .msgBox > .header {
		height: 11.571428571428571rem !important;
	}

	.pageNotFound > .msg > .msgBox > .header > h1 {
		font-size: 11.571428571428571rem !important;
		height: 10.714285714285714rem !important;
		line-height: 11.571428571428571rem !important;
	}

	.pageNotFound > .msg > .msgBox > h2 {
		font-size: 1rem !important;
	}
}

@media only screen and (max-width: 479px) {
	/* smaller than 480px size */

	/*.checkoutView .checkoutTimeline {
		padding: 0 !important;
		margin: 0rem !important;
		width: 100%;
		height: 7rem;
	}

	.checkoutView .checkoutTimeline > .checkpoint > .point {
		width: 8rem;
	}*/

	.footer ._2iWL7 {
		width: auto !important;
	}

	/*.orderDetailView {
		padding: 1rem !important;
	}

	.orderDetailView .address-summary-View {
		flex-wrap: wrap !important;
	}

	.orderDetailView .address-summary-View > .orderSummaryDetailView {
		border-left: none !important;
		border-top: 1px solid rgba(34, 36, 38, 0.15) !important;
	}*/
}

@media only screen and (min-width: 480px) {
	/* greater than 480px size */

	.pageNotFound > .msg > .msgBox > .header {
		height: 11.571428571428571rem !important;
	}

	.pageNotFound > .msg > .msgBox > .header > h1 {
		font-size: 11.571428571428571rem !important;
		height: 10.714285714285714rem !important;
		line-height: 11.571428571428571rem !important;
	}

	.pageNotFound > .msg > .msgBox > h2 {
		font-size: 1rem !important;
	}
}

@media only screen and (min-width: 768px) {
	/* greater than 768px size */

	.pageNotFound > .msg > .msgBox > .header {
		height: 14.285714285714286rem !important;
	}

	.pageNotFound > .msg > .msgBox > .header > h1 {
		font-size: 14.285714285714286rem !important;
	}

	.pageNotFound > .msg > .msgBox > h2 {
		font-size: 1.3rem !important;
	}
}

@media only screen and (max-width: 768px) {
	/* Devices smaller than 768px - Shop Support for mobile devices */

	.ui.container {
		margin-left: auto !important;
		margin-right: auto !important;
	}

	.ui.container.productView {
		padding: 0;
	}

	/* Tablet view, when doubling mode is enables negative margins are added which triggers horizontal scrollbar */
	.itemContainer .ui.doubling.cards {
		margin: 0 !important;
	}

	/*.shopLoginView .ui.container {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	//Not relevent
	.productView > .itemContainer > .itemContentContainer {
		padding: 0;
		margin: 0;
	}

	//Not relevent
	.productView > .itemContainer > .itemContentContainer > .cardView {
		margin: 0;
	}*/

	.productView
		> .itemContainer
		> .itemContentContainer
		> .cardView
		> .cards
		> .card {
		margin-bottom: 2rem;
	}

	/*.collectionSlider .collectionTile.ui.basic.segment {
		display: block !important;
		padding: 1rem 0 0 0 !important;
	}

	.ui.basic.segment.collectionTile > .collectionImages.ui.basic.segment {
		margin-bottom: 0;
	}

	.collectionProfileView > .ui.container.productView {
		margin: 0;
		padding: 0;
	}

	.collectionSliderContainer > .ui.segment.actions {
		padding-right: 0;
		right: 1%;
	}
	
	.ui.collectionInfo > .ui.header.name {
		text-align: center;
		color: var(--secondary-color);
		font-style: normal !important;
		font-size: 2rem !important;
		margin-bottom: 0 !important;
	}

	.ui.collectionInfo .description {
		color: var(--secondary-color-dark);
		text-align: center;
		margin-left: auto;
		margin-right: auto;
	}

	.collectionTile > .collectionInfo.ui.basic.segment {
		max-width: 100% !important;
	}
	

	.featuredCollectionView {
		margin: 0 !important;
		padding: 0 !important;
	}

	.featuredCollectionView > .headerSection {
		display: flex;
		justify-content: space-between;
		padding: 1rem !important;
	}

	.featuredCollectionView .ui.collectionInfo {
		position: absolute;
		bottom: 5%;
		text-align: center;
		padding: 1rem;
		width: 100%;
	}

	.featuredCollectionView .sliderComponentView {
		position: relative;
	}

	.featuredCollectionView .sliderComponentView > .grid::after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #1f1f1f;
		background: linear-gradient(
			360deg,
			rgb(0 0 0 / 80%) 5%,
			rgba(255, 255, 255, 0) 50%
		);
		box-sizing: border-box;
	}*/

	/*.menuSideBar.ui.vertical.menu > .item {
		padding-left: 3rem;
	}

	.menuSideBar.ui.vertical.menu .item.menuSideBar-Item {
		height: calc(var(--block-height) - 1rem);
		width: 100%;
		font-family: var(--primary-font) !important;
		color: var(--secondary-color-darkest);
	}

	.menuSideBar.ui.vertical.menu .active.item.menuSideBar-Item {
		font-weight: 700 !important;
		color: var(--primary-color-dark) !important;
	}

	.profileMenuItem .item.menuSideBar-Item {
		font-size: 0.95rem !important;
	}

	.productProfileView {
		padding: 0 !important;
	}

	.productProfileView .ui.grid.openViewGrid > .row {
		padding-top: 0 !important;
	}

	.productProfileView .ui.message.addedToCartSuccessMsg {
		top: 1% !important;
	}

	.ui.segment.cartView > .orderList {
		padding: 1rem !important;
	}*/

	/*.checkoutView > .grid > .priceSummaryView > div {
		margin-top: 0 !important;
	}

	.checkoutView > .grid > .priceSummaryView.stickyItem {
		position: relative;
	}*/
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
	.cardView .card {
		height: calc(100vh - 26rem);
	}

	.cardView .card > .profile {
		height: 70% !important;
	}

	.cardView .card > .content {
		height: 30% !important;
	}

	.collectionProfileView .cardView .card {
		height: calc(100vh - 25rem);
	}

	.collectionProfileView .cardView .card > .profile {
		height: 73% !important;
	}

	.collectionProfileView .cardView .card > .content {
		height: 27% !important;
	}
}

@media only screen and (min-width: 1280px) and (max-width: 1439px) {
	.cardView .card {
		height: calc(100vh - 12rem);
	}

	.cardView .card > .profile {
		height: 70% !important;
	}

	.cardView .card > .content {
		height: 30% !important;
	}

	.collectionProfileView .cardView .card {
		height: calc(100vh - 11rem);
	}

	.collectionProfileView .cardView .card > .profile {
		height: 73% !important;
	}

	.collectionProfileView .cardView .card > .content {
		height: 27% !important;
	}
}

@media only screen and (min-width: 1440px) and (max-width: 1679px) {
	.cardView .card {
		height: calc(100vh - 15rem);
	}

	.cardView .card > .profile {
		height: 73% !important;
	}

	.cardView .card > .content {
		height: 27% !important;
	}
}

@media only screen and (min-width: 1680px) and (max-width: 1919px) {
	.cardView .card {
		height: calc(100vh - 17rem);
	}

	.collectionProfileView .cardView .card {
		height: calc(100vh - 16rem);
	}
}

@media only screen and (min-width: 1920px) {
	.cardView .card {
		height: calc(100vh - 21rem);
	}

	.collectionProfileView .cardView .card {
		height: calc(100vh - 27rem);
	}
}
